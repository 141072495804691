import classnames from 'classnames'
import React, { type FC } from 'react'
import { ActivityCardMain } from './ActivityCardMain'
import { ActivityCardFooter } from './ActivityCardFooter'
import { LabelTab } from './LabelTab'
import { useStyles } from './useStyles'
import {
  type ActivityContent,
  type ActivityEventType,
} from '../../compositions/ActivityFeed'
import { isNil } from 'lodash'

export interface ActivityProps {
  onSelect?: () => void
  content: ActivityContent
  completed?: boolean
  hideHeader?: boolean
  hideAction?: boolean
  hideStatus?: boolean
  events?: Array<ActivityEventType>
  selected?: boolean
}

export const ActivityFeedCard: FC<ActivityProps> = ({
  content,
  events = [],
  hideStatus = false,
  completed = false,
  onSelect = () => null,
  selected,
}) => {
  const classes = useStyles()
  const {
    stakeholder,
    location,
    activityStatus,
    activityStatusColor,
    stepLabelColor,
    stepLabelName,
    timestamp,
    lastUpdatedTimestamp,
  } = content

  const completedBy = events.find(subActivity => !['Shelly', 'ava', 'Unknown', 'Anonymous'].includes(subActivity.content.avatar) )

  const stakeholdeName = isNil(completedBy) ? stakeholder : completedBy.content.avatar
  return (
    <div
      className={classnames(classes.cardContainer, {
        [classes.selectedCard]: selected,
      })}
    >
      <div className={classes.labelContainer}>
        <LabelTab labelColor={stepLabelColor} labelName={stepLabelName} />
      </div>
      <ActivityCardMain
        content={content}
        hideStatus={hideStatus}
        completed={completed}
        onSelect={onSelect}
      />
      <ActivityCardFooter
        location={location}
        stakeholder={stakeholdeName}
        activityStatus={activityStatus}
        activityStatusColor={activityStatusColor}
        timestamp={timestamp}
        lastUpdatedTimestamp={lastUpdatedTimestamp}
      />
    </div>
  )
}

ActivityFeedCard.displayName = 'ActivityFeedCard'
